import { localEnvConfig, localEnvAuthConfig } from './components/services/api/Constants'
import API_CONFIG from './config/ApiConfig'
import {
  investorsPageInitActions,
  submissionStatusInitActions,
  importConditionsActions,
  importAllConditionsActions,
  documentsInitActions,
  collateralTrackingInitActions,
  deliverConditionStatus,
  multiplePartnersInitActions,
  settingsPage,
} from './config/InitialActions';

(() => {
  const NOCONFIG = true
  window.emEnvConfig = NOCONFIG
  if (window.emEnvConfig === NOCONFIG) window.emEnvConfig = localEnvConfig
  window.emCustomAppConfig = {
    appName: 'Ellie Mae - Investor Portal',
    theme: {
      name: 'blank',
      config: {
        navigation: false,
        header: {},
      },
    },
    authentication: {
      requiresAuthDefault: false,
      authOrigin: localEnvAuthConfig.authOrigin,
      grantAndScope: localEnvAuthConfig.grantAndScope,
      authToken: 'access_token',
      tokenKey: localEnvAuthConfig.tokenKey,
    },
    routes: [
      {
        path: '/',
        page: 'InvestorsPage',
        label: 'InvestorsPage',
        requiresAuth: false,
      },
      {
        path: '/submissionStatus',
        page: 'SubmissionStatus',
        label: 'SubmissionStatus',
        requiresAuth: false,
      },
      {
        path: '/conditions',
        page: 'ImportConditions',
        label: 'importConditions',
        requiresAuth: false,
      },
      {
        path: '/importAllConditions',
        page: 'ImportAllConditions',
        label: 'importAllConditions',
        requiresAuth: false,
      },
      {
        path: '/documents',
        page: 'Documents',
        label: 'Documents',
        requiresAuth: false,
      },
      {
        path: '/funding',
        page: 'Funding',
        label: 'funding',
        requiresAuth: false,
      },
      {
        path: '/shipping',
        page: 'Shipping',
        label: 'shipping',
        requiresAuth: false,
      },
      {
        path: '/bidirectionalPostman',
        page: 'BidirectionalPostman',
        label: 'Partner Response (Investor Connect)',
        requiresAuth: false,
      },
      {
        path: '/deliverConditionStatus',
        page: 'DeliverConditionStatus',
        label: 'Condition Delivery Status',
        requiresAuth: false,
      },
      {
        path: '/settings',
        page: 'Settings',
        label: 'Settings',
        requiresAuth: false,
      },
      {
        path: '/multiplePartners',
        page: 'MultiplePartners',
        label: 'MultiplePartners',
        requiresAuth: false,
      },
    ],
    api: {
      defaultOpts: {
        useAuthToken: false,
        origin: window.emEnvConfig.apiOrigin,
        fetchErrorActionGroup: {},
        codeErrorActionGroup: {},
      },
    },
    apis: API_CONFIG,
    session: {
      beforeUnload: {
        saveState: false,
        stateItemName: 'APP-ENGINE-STATE',
        slicesToSave: {
          data: true,
          field: true,
          manager: true,
        },
      },
      timeout: {
        isEnabled: false,
      },
    },
    pages: {
      InvestorsPage: {
        initActionGroup: investorsPageInitActions,
      },
      SubmissionStatus: {
        initActionGroup: submissionStatusInitActions,
      },
      ImportConditions: {
        initActionGroup: importConditionsActions,
      },
      ImportAllConditions: {
        initActionGroup: importAllConditionsActions,
      },
      Documents: {
        initActionGroup: documentsInitActions,
      },
      Funding: {
        initActionGroup: collateralTrackingInitActions,
      },
      Shipping: {
        initActionGroup: collateralTrackingInitActions,
      },
      DeliverConditionStatus: {
        initActionGroup: deliverConditionStatus,
      },
      MultiplePartners: {
        initActionGroup: multiplePartnersInitActions,
      },
      Settings: {
        initActionGroup: settingsPage,
      },
    },
  }
})()
