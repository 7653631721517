const ACTIONS = {
  GET_ALL: 'GET_ALL',
  GET_ROWS: 'GET_ROWS',
  GET_RETRY_ROWS: 'GET_RETRY_ROWS',
  IMPORT_ALL_CONDITIONS: 'IMPORT_ALL',
  STACKING_TEMPLATE_EVALUATOR: 'STACKING_TEMPLATE_EVALUATOR',
  UPDATE: 'UPDATE',
  GET_CONDITION_STATUS_BY_PARTNERS: 'GET_CONDITION_STATUS_BY_PARTNERS',
  MAP_MULTIPLE_PARTNERS_DATA: 'MAP_MULTIPLE_PARTNERS_DATA',
}

export default ACTIONS
