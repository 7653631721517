/* eslint-disable max-lines */
import ACTIONS from './Actions'
import {
  investorsPage, submissionStatus, importConditions, importAllConditions, documents, collateralTracking, deliverConditions,
} from './InitialState'

const initialStateActions = (object) => Object.keys(object).map((key) => ({
  name: 'appSet',
  opts: {
    name: key,
    data: object[key],
  },
}))

export const investorsPageInitActions = {
  name: 'sequence',
  opts: [
    ...initialStateActions(investorsPage),
    {
      name: 'genericOrchestration',
      opts: {
        name: 'StackingTemplates',
        hash: 'stackingTemplates',
        action: ACTIONS.GET_ALL,
      },
    },
    {
      name: 'genericOrchestration',
      opts: {
        name: 'PartnerPreferences2',
        hash: 'partnerPreferences',
        action: ACTIONS.GET_ALL,
      },
    },
    {
      name: 'genericOrchestration',
      opts: {
        name: 'settings/GetSettings',
        hash: 'settingsData',
      },
    },
  ],
}

export const submissionStatusInitActions = {
  name: 'sequence',
  opts: [
    ...initialStateActions(submissionStatus),
    {
      name: 'genericOrchestration',
      opts: {
        name: 'SubmissionStatus',
        hash: 'submissionStatus',
        action: ACTIONS.GET_ROWS,
        from: 0,
        amount: 50,
        sort: {},
      },
    },
    {
      name: 'genericOrchestration',
      opts: {
        name: 'GetEICSettings',
        hash: 'getEICSettings',
        key: 'eic.ui.docviewer.enable.fromdate',
      },
    },
    {
      name: 'genericOrchestration',
      opts: {
        name: 'GetPermissions',
        hash: 'getPermissions',
        opts: {
          categories: 'Tools,sellConditions,EnhancedConditions,Documents',
          errorModal: {
            show: false,
            onRetry: [],
            onReject: [],
          },
        },
      },
    },
    {
      name: 'genericOrchestration',
      opts: {
        name: 'settings/GetSettings',
        hash: 'settingsData',
      },
    },
  ],
}

export const importConditionsActions = {
  name: 'sequence',
  opts: [
    ...initialStateActions(importConditions),
  ],
}

export const importAllConditionsActions = {
  name: 'sequence',
  opts: [
    ...initialStateActions(importAllConditions),
    {
      name: 'genericOrchestration',
      opts: {
        name: 'GetActiveTypes',
        hash: 'activeTypes',
        typesToCheck: ['Investor Delivery'],
      },
    },
    {
      name: 'genericOrchestration',
      opts: {
        name: 'GetPermissions',
        hash: 'getPermissions',
        opts: {
          categories: 'EnhancedConditions',
          errorModal: {
            show: false,
            onRetry: [],
            onReject: [],
          },
        },
      },
    },
  ],
}

export const documentsInitActions = {
  name: 'sequence',
  opts: [
    ...initialStateActions(documents),
    {
      name: 'genericOrchestration',
      opts: {
        name: 'GetPermissions',
        hash: 'getPermissions',
        opts: {
          categories: 'Documents',
          errorModal: {
            show: false,
            onRetry: [],
            onReject: [],
          },
        },
        loader: {
          isOpen: false,
        },
      },
    },
  ],
}

export const collateralTrackingInitActions = {
  name: 'sequence',
  opts: [
    ...initialStateActions(collateralTracking),
    {
      name: 'genericOrchestration',
      opts: {
        name: 'GetPermissions',
        hash: 'getPermissions',
        opts: {
          categories: 'Tools',
          errorModal: {
            show: false,
            onRetry: [],
            onReject: [],
          },
        },
      },
    },
    {
      name: 'genericOrchestration',
      opts: {
        name: 'LoanPipeline',
        hash: 'loanPipeline',
        useActualLoan: true,
      },
    },
  ],
}

export const deliverConditionStatus = {
  name: 'genericOrchestration',
  opts: {
    name: 'GetActiveTypes',
    hash: 'activeTypes',
    typesToCheck: ['Investor Delivery'],
    loader: {
      isOpen: false,
    },
    displayErrorToast: false,
  },
}

export const multiplePartnersInitActions = {
  name: 'sequence',
  opts: [{
    name: 'genericOrchestration',
    opts: {
      name: 'GetActiveTypes',
      hash: 'activeTypes',
      typesToCheck: ['Investor Delivery'],
      loader: {
        isOpen: false,
      },
    },
  },
  ...initialStateActions(deliverConditions),
  ],
}

export const settingsPage = {
  name: 'sequence',
  opts: [{
    name: 'genericOrchestration',
    opts: {
      name: 'settings/GetSettings',
      hash: 'settingsData',
      getActiveUsers: true,
      getSubscriptions: true,
      settingsPage: true,
    },
  }, {
    name: 'genericOrchestration',
    opts: {
      name: 'PipelineInvestors',
      hash: 'partnersAndCategory',
      action: ACTIONS.GET_ALL,
    },
  }, {
    name: 'genericOrchestration',
    opts: {
      name: 'ValidPolicies',
      hash: 'policiesData',
      checkPolicies: ['enhancedconditionsworkflow'],
      getPolicies: ['enhancedconditionsworkflowstdate'],
    },
  }],
}
