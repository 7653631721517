/* eslint-disable max-lines */
import {
  GET_OPTIONS, POST_OPTIONS, PUT_OPTIONS, PATCH_OPTIONS,
} from './options'

const globalRequests = {
  getPermissions: {
    ...GET_OPTIONS,
    url: '/encompass/v3/users/:user/effectiverights?categories=:categories',
  },
  getUserPersona: {
    ...GET_OPTIONS,
    url: '/encompass/v1/users/:user',
  },
  logError: {
    ...POST_OPTIONS,
    url: '/investor/v1/logger',
  },
  getEICSettings: {
    ...GET_OPTIONS,
    url: '/investor/v1/settings?keys=:key',
  },
  getActiveTypes: {
    ...GET_OPTIONS,
    url: '/encompass/v3/settings/loan/conditions/types',
  },
}

const investorsPageRequests = {
  getPipelineInvestors: {
    ...GET_OPTIONS,
    url: '/investor/v1/products?start=:start&limit=100',
  },
  getPipelineInvestors2: {
    ...GET_OPTIONS,
    url: '/encservices/v1/products',
  },
  getPipelinePermissions: {
    ...GET_OPTIONS,
    url: '/encompass/v3/users/:user/effectiverights?categories=Services',
  },
  getPartnerPreferences: {
    ...GET_OPTIONS,
    url: '/services/v1/partners/:id/product/:product/preferences?key=:key&version=:version',
  },
  getPartnerPreferences2: {
    ...GET_OPTIONS,
    url: '/investor/v1/partners/:id/products/:product/preferences',
  },
  getAllStackingTemplates: {
    ...GET_OPTIONS,
    url: '/encompass/v1/settings/efolder/documentstackingtemplates',
  },
  getLoanPipeline: {
    ...POST_OPTIONS,
    url: '/encompass/v3/loanPipeline?ignoreInvalidFields=true',
  },
  getLoanPipelinePagination: {
    ...POST_OPTIONS,
    url: '/encompass/v3/loanPipeline?ignoreInvalidFields=true&start=:start&limit=999',
  },
  getDocuments: {
    ...POST_OPTIONS,
    url: '/encompass/v1/calculators/stackingTemplate?includeDocumentDetails=true',
  },
  getEFolderDocuments: {
    ...GET_OPTIONS,
    url: '/encompass/v1/loans/:loanId/documents?filters=hasActiveVersion',
  },
  createPackage: {
    ...POST_OPTIONS,
    url: '/encompass/v1/efolderservices/packages',
  },
  getDocumentsSettings: {
    ...GET_OPTIONS,
    url: '/encompass/v3/loans/:loanId/attachmentSettings',
  },
  getDocumentUrl: {
    ...POST_OPTIONS,
    url: '/encompass/v3/loans/:loanId/attachmentDownloadUrl?includeMetadata=true',
  },
}

const updateStackingTemplateRequests = {
  getSourceDocuments: {
    ...GET_OPTIONS,
    url: '/encompass/v1/settings/eds/stackingElements?orderType=:orderType&source=:sourceType',
  },
  getEFolderSourceDocuments: {
    ...GET_OPTIONS,
    url: '/encompass/v1/settings/efolder/documents?includeDocument=true&includeVerificationDocuments=true',
  },
  getStackingTemplateById: {
    ...GET_OPTIONS,
    url: '/encompass/v1/settings/efolder/documentStackingTemplates/:id',
  },
}

const submissionStatusRequests = {
  getAllSubmissionStatus: {
    ...POST_OPTIONS,
    url: '/encompass/v1/efolderservices/loanSelector?start=:from&limit=:amount',
  },
  getResubmitSubmissionStatus: {
    ...POST_OPTIONS,
    // url: '/encompass/v1/efolderservices/loanSelector?start=:from&limit=:amount&userLoanAccess=true', OLD
    url: '/encompass/v1/efolderservices/loanSelector?start=:from&limit=:amount',
  },
  getAllLoans: {
    ...GET_OPTIONS,
    url: '/investor/v1/loans?resource=:resources',
  },
  getAllLoansResources: {
    ...GET_OPTIONS,
    url: '/investor/v1/loans?',
  },
  getLoanStatusInfo: {
    ...GET_OPTIONS,
    url: '/investor/v1/loans/:loanId/response',
  },
  getFilesList: {
    ...GET_OPTIONS,
    url: '/investor/v1/packages/:packageId/loans/:loanId/files?generateUrl=true',
  },
  getFileData: {
    ...POST_OPTIONS,
    url: '/investor/v1/packages/:packageId/loans/:loanId/files/:fileId/urlGenerator?externalFile=true',
  },
  generateFileUrl: {
    ...POST_OPTIONS,
    url: '/investor/v1/Files/generateURLToDownLoadFileUsingPOST?externalFile=false',
  },
  resubmitTransaction: {
    ...PATCH_OPTIONS,
    url: '/investor/v1/packages/:packageId/loans/:loanId/submissions',
  },
}

const conditionsRequests = {
  getConditionsImport: {
    ...GET_OPTIONS,
    url: '/investor/v1/loans/:loanId/response?resource=conditions&applyRule=ReviewImport',
  },
}

const importAll = {
  getImportAll: {
    ...GET_OPTIONS,
    url: '/investor/v1/loans/:loanId/response?resource=conditions&applyRule=import',
  },
}

const collateralTrackingRequests = {
  getCollateralTracking: {
    ...GET_OPTIONS,
    url: '/investor/v1/loans/:loan_id/response?resource=:resource',
  },
  importTransaction: {
    ...POST_OPTIONS,
    url: '/investor/v1/loans/:loan_id/resourceImporter?timeZoneOffset=:timezone_offset',
  },
  importTransactionv2: {
    ...POST_OPTIONS,
    opts: {
      entireResult: true,
      codesAllowed: [403],
    },
    url: '/investor/v2/loans/:loan_id/resourceImporter?timeZoneOffset=:timezone_offset',
  },
}

const bidirectionalPostman = {
  executePostmanLoan: {
    ...POST_OPTIONS,
    url: '/partner/v1/transactions/:loanId/response',
    opts: {
      ...POST_OPTIONS.opts,
    },
  },
  getDropFileLink: {
    ...GET_OPTIONS,
    url: '/partner/v1/transactions/:id/dropFiles',
  },
}

const deliverConditionStatus = {
  getDeliverConditionStatus: {
    ...GET_OPTIONS,
    url: '/investor/v1/loans/:loan_id/response?resource=conditions&applyRule=ReviewImport',
  },
}

const deliverCondition = {
  getDeliverCondition: {
    ...GET_OPTIONS,
    url: '/investor/v1/loans/:loan_id/response?resource=conditions&applyRule=Delivery',
  },
}

const settingsPage = {
  getSettingsData: {
    ...GET_OPTIONS,
    url: '/investor/v1/lenderSettings',
  },
  getSubscriptionsData: {
    ...GET_OPTIONS,
    url: '/investor/v1/subscriptions',
  },
  getActiveUsers: {
    ...GET_OPTIONS,
    url: '/encompass/v1/company/users?start=:start&limit=100',
  },
  saveSettings: {
    ...PUT_OPTIONS,
    url: '/investor/v1/lenderSettings',
  },
  saveSubscriptions: {
    ...PUT_OPTIONS,
    url: '/investor/v1/subscriptions?id=:subscription_id',
  },
  getDropdownOptions: {
    ...GET_OPTIONS,
    url: '/services/v1/partners/:id/product/:product/preferences?key=:key&version=:version',
  },
  getPolicies: {
    ...GET_OPTIONS,
    url: '/encompass/v1/company/systemoptions/policies',
  },
}

const API_CONFIG = {
  ...globalRequests,
  ...investorsPageRequests,
  ...updateStackingTemplateRequests,
  ...submissionStatusRequests,
  ...conditionsRequests,
  ...importAll,
  ...collateralTrackingRequests,
  ...bidirectionalPostman,
  ...deliverConditionStatus,
  ...deliverCondition,
  ...settingsPage,
}

export default API_CONFIG
