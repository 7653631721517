const OPTIONS = {
  entireResult: true,
}

export const GET_OPTIONS = {
  fetchInit: {
    method: 'GET',
  },
  opts: OPTIONS,
}

export const DELETE_OPTIONS = {
  fetchInit: {
    method: 'DELETE',
  },
  opts: OPTIONS,
}

export const POST_OPTIONS = {
  fetchInit: {
    method: 'POST',
  },
  opts: OPTIONS,
}

export const PATCH_OPTIONS = {
  fetchInit: {
    method: 'PATCH',
  },
  opts: OPTIONS,
}

export const PUT_OPTIONS = {
  fetchInit: {
    method: 'PUT',
  },
  opts: OPTIONS,
}
