const queryParmeters = new URLSearchParams(window.location.search)
const URL = window.location.hostname

export const checkEnvironment = (envToCheck) => {
  let actualEnvironment = ''

  if (!queryParmeters.has('mockEnv')) {
    if (
      URL.includes('localhost')
      || URL.includes('int')
      || URL.includes('ssftest')
    ) {
      actualEnvironment = 'test'
    } else if (URL.includes('dev')) {
      actualEnvironment = 'dev'
    } else if (URL.includes('qa')) {
      actualEnvironment = 'qa'
    } else if (URL.includes('peg')) {
      actualEnvironment = 'peg'
    } else if (URL.includes('stg') && URL.includes('ellielabs')) {
      actualEnvironment = 'stg'
    } else if (URL.includes('ellieservices')) {
      actualEnvironment = 'prod'
    }

    if (URL.includes('int') && queryParmeters.has('env') && queryParmeters.get('env') === 'cr') {
      actualEnvironment = 'concept'
    }

    return actualEnvironment === envToCheck
  }
    return queryParmeters.get('mockEnv') === envToCheck
}
