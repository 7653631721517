export const investorsPage = {
  modals: {
    reviewSummary: {
      open: false,
    },
    reviewDocuments: {
      open: false,
    },
    confirmation: {
      open: false,
    },
    addEFolderDocumentsWarning: {
      open: false,
    },
    reviewSummaryCancelWarning: {
      open: false,
    },
    investorSubmissionClose: {
      open: false,
    },
  },
  loans: [],
  personaEvaluator: {
    authorizedToUpdateStackingTemplate: false,
  },
  stackingTemplates: {
    default: {},
    values: [],
    error: false,
  },
  updatedStackingTemplate: {},
  errorModal: {
    show: false,
    onRetry: [],
    onReject: [],
  },
  sourcesDocuments: {
    eDisclosuresReturned: [],
    eDisclosuresDefault: [],
    closingDocsReturned: [],
    closingDocsDefault: [],
    eFolderDocs: [],
  },
  stackingTemplate: {},
}

export const submissionStatus = {
  modals: {
    confirmation: {
      open: false,
    },
  },
  errorModal: {
    show: false,
    onRetry: [],
    onReject: [],
  },
}

export const importConditions = {
  errorModal: {
    show: false,
    onRetry: [],
    onReject: [],
  },
  conditionsSources: {
    error: false,
    data: [],
    selected: {},
  },
  conditions: {
    total: [],
    selected: [],
  },
}

export const importAllConditions = {
  errorModal: {
    show: false,
    onRetry: [],
    onReject: [],
  },
  conditionsSources: {
    error: false,
    data: [],
    selected: {},
  },
  conditions: {
    total: [],
    selected: [],
  },
}

export const documents = {
  errorModal: {
    show: false,
    onRetry: [],
    onReject: [],
  },
}

export const collateralTracking = {
  errorModal: {
    show: false,
    onRetry: [],
    onReject: [],
  },
}

export const deliverConditions = {
  modals: {
    confirmation: {
      open: false,
    },
  },
}
